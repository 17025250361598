import './App.css';
import './tailwind.output.css';
import Home from './Home';
import Layout from './Layout';
import NoPage from './NoPage';
import Schedule from './Schedule';
import Resources from './Resources';
import Teams from './Teams';
import RegistrationForm from './RegistrationForm';
import Profile from './Profile';
import Privacy from './Privacy';
import Waiver from './Waiver';
import {
  BrowserRouter, 
  Route, 
  Routes
} from "react-router-dom";
import FAQ from './FAQ';

function App() {

  // const [data, setData] = React.useState(null);

  // React.useEffect(() => {
  //   fetch("/api/test")
  //     .then((res) => res.json())
  //     .then((data) => setData(data.map((i: any) => i.Title).join(' ')));
  // }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          {/* <Route path="register" element={<RegistrationForm />} /> */}
          <Route path="schedule" element={<Schedule />} />
          <Route path="teams" element={<Teams />} />
          <Route path="resources" element={<Resources />} />
          <Route path="faq" element={<FAQ />} />
          <Route path="profile" element={<Profile />} />
          <Route path="authorize" element={<Profile />} />
          <Route path="privacy-policy" element={<Privacy />} />
          <Route path="waiver-policy" element={<Waiver />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
