// import * as react from 'react'
import { useEffect, useState, Fragment } from 'react';
// import { Dialog, Transition } from '@headlessui/react'
import './App.css';
import './tailwind.output.css';

import moment from 'moment';

interface IEvent {
    Id: number;
    Title: string;
    Start: string;
    End: string;
    Notes: string;
    Teams: {
        Title: '4th' | '5th' | '6th' | '7th';
    }[];
    Color?: string;
    TextColor?: string;
    Gender: 'Girls' | 'Boys';
}

interface IScheduleEvent {
    title: string;
    events: Array<IEvent>;
}

interface ISchedules {
    four_g: IScheduleEvent;
    six_g: IScheduleEvent;
    seven_g: IScheduleEvent;
    four_b: IScheduleEvent;
    five_b: IScheduleEvent;
}

function Schedule() {

    const [events, setEvents] = useState<ISchedules>({
        four_g: {
            title: '4th Grade Girls',
            events: []
        },
        six_g: {
            title: '6th Grade Girls',
            events: []
        },
        seven_g: {
            title: '7th Grade Girls',
            events: []
        },
        four_b: {
            title: '4th Grade Boys',
            events: []
        },
        five_b: {
            title: '5th Grade Boys',
            events: []
        }
    });
    const [isLoading, setLoading] = useState<any>(true);

    useEffect(() => {
        fetch("/api/schedule", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                schedule: 'Tournament Schedule'
            })
        })
            .then((response) => response.json())
            .then((data: Array<IEvent>) => {
                setEvents({
                    four_g: {
                        title: '4th Grade Girls',
                        events: data.filter((i: IEvent) => i.Gender === 'Girls' && i.Teams.filter(i => i.Title === '4th').length === 1)
                    },
                    six_g: {
                        title: '6th Grade Girls',
                        events: data.filter((i: IEvent) => i.Gender === 'Girls' && i.Teams.filter(i => i.Title === '6th').length === 1)
                    },
                    seven_g: {
                        title: '7th Grade Girls',
                        events: data.filter((i: IEvent) => i.Gender === 'Girls' && i.Teams.filter(i => i.Title === '7th').length === 1)
                    },
                    four_b: {
                        title: '4th Grade Boys',
                        events: data.filter((i: IEvent) => i.Gender === 'Boys' && i.Teams.filter(i => i.Title === '4th').length === 1)
                    },
                    five_b: {
                        title: '5th Grade Boys',
                        events: data.filter((i: IEvent) => i.Gender === 'Boys' && i.Teams.filter(i => i.Title === '5th').length === 1)
                    }
                });

                setLoading(false);
            })
    }, []);


    const ScheduleEvents = (schedule: IScheduleEvent) => {
        return (
            <div className="px-4 sm:px-6 lg:px-8 mb-10">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h1 className="text-xl font-semibold text-gray-900">{schedule.title}</h1>
                        <p className="mt-2 text-sm text-gray-700">
                            Below you will find the current list of tournaments for the {schedule.title}.
                        </p>
                    </div>
                </div>
                <div className="mt-8 flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th scope="col"  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 w-2/4">
                                                Location
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-2/4">
                                                Date
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white">
                                        {
                                            schedule.events.map((event) => (
                                                <tr key={event.Id}>
                                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                        {event.Title}
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                        {
                                                            event.Start !== event.End &&
                                                            <div>
                                                                {moment(event.Start).format('MM/DD/YYYY')} - {moment(event.End).format('MM/DD/YYYY')}
                                                            </div>
                                                        }
                                                        {
                                                            event.Start === event.End &&
                                                            <div>
                                                                {moment(event.Start).format('MM/DD/YYYY')}
                                                            </div>
                                                        }
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="px-4 sm:px-6 lg:px-8 mt-20">
            {
                isLoading
                    ? <div style={{
                        width: '100%',
                        margin: 'auto auto',
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <img src="/loading.svg" />
                    </div>
                    : <>
                        <ScheduleEvents title={events.four_g.title} events={events.four_g.events} />

                        <ScheduleEvents title={events.six_g.title} events={events.six_g.events} />

                        <ScheduleEvents title={events.seven_g.title} events={events.seven_g.events} />

                        <ScheduleEvents title={events.four_b.title} events={events.four_b.events} />

                        <ScheduleEvents title={events.five_b.title} events={events.five_b.events} />
                    </>
            }
        </div>
    );
}

export default Schedule;
