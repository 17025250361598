import './App.css';
import {
    Link
} from "react-router-dom";

const coaches = [
    {
        name: 'Mike Feterl',
        imageUrl:
            'https://th.bing.com/th/id/R.90da7587e7894a1bb7d9a6620e238a7e?rik=7JCY%2fmz0XFUffg&riu=http%3a%2f%2fwww.alloyinsured.com%2fuploads%2f1%2f1%2f6%2f0%2f116028151%2fpublished%2fmike-alloy-web-photos-2020.jpg%3f1605290409&ehk=o437yrJnSOUeS3eqNOUKWiWQPM320ofY2t%2bMr6OkLn0%3d&risl=&pid=ImgRaw&r=0',
    },
]
const players = [
    {
        name: 'Addison',
        number: 5,
        imageUrl:
            '6g5p.PNG',
    },
    {
        name: 'Ashley',
        number: 22,
        imageUrl:
            '6g22p.PNG',
    },
    {
        name: 'Jayla',
        number: 12,
        imageUrl:
            '6g12p.PNG',
    },
    {
        name: 'Rubi',
        number: 3,
        imageUrl:
            '6g3p.PNG',
    },
    {
        name: 'Alexis',
        number: 20,
        imageUrl:
            '6g20p.PNG',
    },
    {
        name: 'Kaden',
        number: 15,
        imageUrl:
            '6g15p.PNG',
    },
    {
        name: 'Lilly',
        number: 11,
        imageUrl:
            '6g11p.PNG',
    },
    {
        name: 'McKayla',
        number: 21,
        imageUrl:
            '6g21p.PNG',
    },
    {
        name: 'Rihanna',
        number: 14,
        imageUrl:
            '6g14p.PNG',
    }
]

function Teams() {
    return (
        <div className="bg-white min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
            <div className="max-w-max mx-auto">
                <div className='players'>
                    {
                        players.sort((a, b) => a.number < b.number ? -1 : 1).map(player => {
                            return (
                                <div className='playingCard'>
                                    <div className='number'>
                                        #{player.number}
                                    </div>
                                    <img src={`/${player.imageUrl}`} alt="Addison" />
                                    <div className='name'>
                                        {player.name}
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
                <main className="sm:flex">
                    <p className="text-4xl font-extrabold text-indigo-600 sm:text-5xl">Coming Soon</p>
                    <div className="sm:ml-6">
                        <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                            <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">Registration is open</h1>
                            <p className="mt-1 text-base text-gray-500">Once registrations is closed, we will update this page with our athletes for the upcoming season.</p>
                        </div>
                        <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                            <Link
                                to="/register"
                                className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-black bg-gold-500 text-black hover:bg-gold sm:px-8"
                            >
                                Register Now
                            </Link>
                        </div>
                    </div>
                </main>
            </div>
        </div>
        // <div className="bg-white">
        //     <div className="border rounded-md mx-auto mt-10 py-12 px-4 max-w-6xl sm:px-6 lg:px-8 lg:py-24">
        //         <div className="grid grid-cols-1 gap-12 lg:grid-cols-3 lg:gap-8">
        //             <div className="space-y-5 sm:space-y-4">
        //                 <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">4th grade girls</h2>
        //                 <p className="text-xl text-gray-500">
        //                     The 5th graders played together last year and are looking forward to a competitive season!
        //                 </p>
        //                 <h3 className="text-2xl font-extrabold tracking-tight sm:text-3xl uppercase">Coaches</h3>
        //                 <ul className="space-y-12 sm:grid sm:grid-cols-1 sm:gap-12 sm:space-y-0 lg:gap-x-8">
        //                     {coaches.map((person) => (
        //                         <li key={person.name}>
        //                             <div className="flex items-center space-x-4 lg:space-x-6">
        //                                 <img className="w-16 h-16 rounded-full lg:w-20 lg:h-20" src={person.imageUrl} alt="" />
        //                                 <div className="font-medium text-lg leading-6 space-y-1">
        //                                     <h3>{person.name}</h3>
        //                                 </div>
        //                             </div>
        //                         </li>
        //                     ))}
        //                 </ul>
        //             </div>
        //             <div className="lg:col-span-2">
        //                 <ul className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-12 sm:space-y-0 lg:gap-x-8">
        //                     {people.map((person) => (
        //                         <li key={person.name}>
        //                             <div className="flex items-center space-x-4 lg:space-x-6">
        //                                 <img className="w-16 h-16 rounded-full lg:w-20 lg:h-20" src={person.imageUrl} alt="" />
        //                                 <div className="font-medium text-lg leading-6 space-y-1">
        //                                     <h3>{person.name}</h3>
        //                                 </div>
        //                             </div>
        //                         </li>
        //                     ))}
        //                 </ul>
        //             </div>
        //         </div>
        //     </div>

        // </div>
    );
}

export default Teams;
