import './App.css';

const videos = [
    {
        url: 'https://www.youtube.com/embed/XzT5-AKmXEA'
    },
    {
        url: 'https://www.youtube.com/embed/_cGfvoLibN4'
    },
    {
        url: 'https://www.youtube.com/embed/32dOSPxfcQw'
    },
    {
        url: 'https://www.youtube.com/embed/BSzSt1NWAwU'
    }
]

function Resources() {
    return (
        <div className="bg-white">
            <div className="border rounded-md mx-auto mt-10 py-12 px-4 max-w-6xl sm:px-6 lg:px-8 lg:py-24">
                <div className="mb-10">
                    <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">Dribble/Shooting Drills</h2>
                </div>
                <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                    {videos.map((video, index) => (
                        <li key={index} className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
                            <iframe width={'100%'} height={200} src={video.url} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default Resources;
