import './App.css';
import './tailwind.output.css';
import { CheckIcon, PhotographIcon, DocumentTextIcon, InformationCircleIcon, DocumentAddIcon } from '@heroicons/react/outline'
import { Link } from 'react-router-dom';

const supportLinks = [
    {
        name: 'Registration',
        href: 'https://go.teamsnap.com/forms/383513',
        description:
            'Click here to learn more about registration, fees and sign-up your child for the upcoming season.',
        icon: DocumentAddIcon,
        buttonText: 'Register Now'
    },
    {
        name: 'Parent/Player Code of Conduct',
        href: 'https://storage.googleapis.com/ts_assets_prod-registration_form_documents/132997/SEBA%20Code%20of%20Conduct.pdf?1693349094',
        description:
            'All of our players (and parents) sign contracts just like the big leagues! Our contracts outline our player/parent conduct that helps promote character and skill.',
        icon: DocumentTextIcon,
        buttonText: 'View Now'
    },
    {
        name: 'Frequently Asked Questions',
        href: '/faq',
        description:
            'Have questions about our program? Start here to find answers to commonly asked questions.',
        icon: InformationCircleIcon,
        buttonText: 'Get answers'
    },
]

const features = [
    {
        name: 'Teach and develop individual basketball skills.',
        description: null
    },
    { name: 'Develop team concepts and ideals', description: null },
    {
        name: 'Teach players, coaches, and parents to be respectful to each other, opponents, and the game of basketball.',
        description: null
    },
    { name: 'Emphasize characteristics like hard work, dedication, and perseverance that transfer to areas outside of basketball.', description: null },
    { name: 'Help players reach their full potential as basketball players.', description: null },
    { name: 'Create an environment where each team member feels they serve an important and meaningful role on his/her team', description: null },
    { name: 'Learn to win or lose with class and good sportsmanship', description: null },
    { name: 'Be competitive at every level', description: null },
]

function Home() {
    return (
        <>
            <div>
                <div className="bg-white">
                    {/* Header */}
                    <div className="relative pb-32 bg-gray-800">
                        <div className="absolute inset-0">
                            <img
                                className="w-full h-full object-cover"
                                src="https://thumbs.dreamstime.com/b/kids-bright-sportswear-playing-basketball-running-ball-game-197642242.jpg"
                                alt=""
                            />
                            <div className="absolute inset-0 bg-gray-700 mix-blend-multiply" aria-hidden="true" />
                        </div>
                        <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
                            <h1 className="text-4xl font-extrabold tracking-tight text-white md:text-5xl lg:text-6xl">Sibley East Basketball Association</h1>
                            <p className="mt-6 max-w-4xl text-xl text-gray-300">
                                The SEBA basketball program is currently for grades 3 through 8 and focuses on continuous development of basic basketball fundamentals and skill sets as well as team improvements for boys and girls.  The program is designed to challenge the players through increased competition in weekend tournaments as the player's skill sets improve from year to year.
                            </p>
                        </div>
                    </div>

                    {/* Overlapping cards */}
                    <section
                        className="-mt-32 max-w-7xl mx-auto relative z-10 pb-32 px-4 sm:px-6 lg:px-8"
                        aria-labelledby="contact-heading"
                    >
                        <h2 className="sr-only" id="contact-heading">
                            Contact us
                        </h2>
                        <div className="grid grid-cols-1 gap-y-20 lg:grid-cols-3 lg:gap-y-0 lg:gap-x-8">
                            {supportLinks.map((link) => (
                                <div key={link.name} className="flex flex-col bg-white rounded-2xl shadow-xl">
                                    <div className="flex-1 relative pt-16 px-6 pb-8 md:px-8">
                                        <div className="absolute top-0 p-5 inline-block bg-gold-500 rounded-xl shadow-lg transform -translate-y-1/2">
                                            <link.icon className="h-6 w-6 text-black" aria-hidden="true" />
                                        </div>
                                        <h3 className="text-xl font-medium text-gray-900">{link.name}</h3>
                                        <p className="mt-4 text-base text-gray-500">{link.description}</p>
                                    </div>
                                    <div className="p-6 bg-gray-50 rounded-bl-2xl rounded-br-2xl md:px-8">
                                        {
                                            link.buttonText !== 'Coming Soon' && link.href.startsWith('http') &&
                                            <a className="text-base font-medium text-black hover:text-gold-600" href={link.href} target="_blank" rel='noreferrer'>
                                                {link.buttonText}<span aria-hidden="true"> &rarr;</span>
                                            </a>
                                        }
                                        {
                                            link.buttonText !== 'Coming Soon' && !link.href.startsWith('http') &&
                                            <Link className="text-base font-medium text-black hover:text-gold-600" to={link.href}>
                                                {link.buttonText}<span aria-hidden="true"> &rarr;</span>
                                            </Link>
                                        }
                                        {
                                            link.buttonText === 'Coming Soon' &&
                                            <div className='text-base font-medium text-black hover:text-gold-600'>{link.buttonText}</div>
                                        }
                                    </div>
                                </div>
                            ))}
                        </div>
                    </section>
                </div>

                <div className="bg-gray">
                    <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8">
                        <div>
                            <h2 className="text-base font-semibold text-gray-700 uppercase tracking-wide">Basketball Program</h2>
                            <p className="mt-2 text-3xl font-extrabold text-gray-900">Overall Program Goals</p>
                            <p className="mt-4 text-lg text-gray-500">
                                The program is designed to challenge the players through increased competition in weekend tournaments as the player's skill sets improve from year to year.
                            </p>
                        </div>
                        <div className="mt-12 lg:mt-0 lg:col-span-2">
                            <dl className="space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:grid-rows-4 sm:grid-flow-col sm:gap-x-6 sm:gap-y-10 lg:gap-x-8">
                                {features.map((feature) => (
                                    <div key={feature.name} className="relative">
                                        <dt>
                                            <CheckIcon className="absolute h-6 w-6 text-gold" aria-hidden="true" />
                                            <p className="ml-9 text-lg leading-6 font-medium text-gray-900">{feature.name}</p>
                                        </dt>
                                        <dd className="mt-2 ml-9 text-base text-gray-500">{feature.description}</dd>
                                    </div>
                                ))}
                            </dl>
                        </div>
                    </div>
                </div>
                <div>
                    {/* Hero card */}
                    <div className="relative">
                        <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gray-200" />
                        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                            <div className="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
                                <div className="absolute inset-0">
                                    <img
                                        className="h-full w-full object-cover"
                                        src="https://media.istockphoto.com/photos/we-should-find-the-way-to-celebrate-this-win-picture-id1150738196?k=20&m=1150738196&s=612x612&w=0&h=WO31ajXMbX7ihgJr-b4bB-1oz0b4uBAJ3UoatjnFk2g="
                                        alt="Teamates playing basketball"
                                    />
                                    <div className="absolute inset-0 bg-gray-600 mix-blend-multiply" />
                                </div>
                                <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
                                    <h1 className="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
                                        <span className="block text-white">Level up</span>
                                        <span className="block text-gold-600">register today!</span>
                                    </h1>
                                    <p className="mt-6 max-w-lg mx-auto text-center text-xl text-indigo-200 sm:max-w-3xl">
                                        Watch your child grow not only as a basketball player, but also as a person.
                                    </p>
                                    <div className="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
                                        <div className="space-y-4 sm:space-y-0 sm:mx-auto sm:inline-grid sm:grid-cols-1 sm:gap-5">
                                            <a
                                                href="https://go.teamsnap.com/forms/383513"
                                                target='_blank'
                                                rel='noreferrer'
                                                className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-black bg-white hover:bg-gold sm:px-8"
                                            >
                                                Register Now
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Logo cloud */}
                    <div className="bg-gray-200">
                        <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
                            <p className="text-center text-sm font-semibold uppercase text-gray-500 tracking-wide">
                                Thank you to the businesses that support our program!
                            </p>
                            <div className="mt-6 grid gap-8 md:grid-cols-4 lg:grid-cols-4 sm:grid-cols-2">
                                <div className="flex justify-center md:col-span-1 lg:col-span-1 sm:col-span-1">
                                    <a href="https://michaelfoods.com/" target="_blank"><img className="h-16" src="./michaelfoods.png" alt="Michael Foods" /></a>
                                </div>
                                <div className="flex justify-center md:col-span-1 lg:col-span-1 sm:col-span-1">
                                    <a href="https://www.arlingtonstatebank.com/" target="_blank"><img className="h-16" src="./asb.png" alt="Arlington State Bank" /></a>
                                </div>
                                <div className="flex justify-center md:col-span-1 lg:col-span-1 sm:col-span-1">
                                    <a href="https://www.cityofgreenislemn.org/community/green-isle-irish-baseball/" target="_blank"><img className="h-16" src="gibc.png" alt="Green Isle Baseball Club" /></a>
                                </div>
                                <div className="flex justify-center md:col-span-1 lg:col-span-1 sm:col-span-1">
                                    <a href="https://www.greenislecommunityschool.org/" target="_blank"><img className="h-16" src="gipc.png" alt="Green Isle Public Schools" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Home;
