import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Switch } from '@headlessui/react';
import moment from 'moment';

interface IRegistration {
  Id: number;
  Title: string;
  FirstName: string;
  LastName: string;
  Email: string;
  ReceiveTexts: boolean;
  MediaConsent: boolean;
  ReceiveEmail: boolean;
  Phone: string;
  Address: string;
  City: string;
  State: string;
  Zip: string;
  ParentName: string;
  AthleteFirstName: string;
  AthleteLastName: string;
  Grade: string;
  Gender: string;
  Birthday: string;
  AmountPaid: string;
  Paid: string;
  PaymentData: string;
  Team: {
    Title: string;
    Id: number;
    RegistrationFee: number;
  }
  ConfirmationNumber: string;
  RegistrationEmail: string;
}

interface IGrade {
  id: number;
  text: string;
  fee: number;
}

const classNames = (...classes: Array<string>) => {
  return classes.filter(Boolean).join(' ')
}

const Profile = () => {

  const { user, isAuthenticated, isLoading } = useAuth0();
  const [registrations, setRegistrations] = useState<Array<IRegistration>>([]);
  const genders = [
    {
      id: 'Boy',
      name: 'Male'
    },
    {
      id: 'Girl',
      name: 'Female'
    }
  ];

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [address1, setAddress1] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');
  const [email, setEmail] = useState(user?.email ? user.email : '');
  const [phone, setPhone] = useState('');
  const [notifyEmail, setNotifyEmail] = useState(true);
  const [notifySms, setNotifySms] = useState(true);

  useEffect(() => {
    if (user) {
      fetch("/api/registrations", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          Email: user.email
        })
      })
        .then(res => res.json())
        .then(data => {
          if (data?.length > 0) {
            setRegistrations(data);
            setFirstName(data[0].FirstName);
            setLastName(data[0].LastName);
            setEmail(data[0].Email);
            setPhone(data[0].Phone);
            setAddress1(data[0].Address);
            setCity(data[0].City);
            setState(data[0].State);
            setZip(data[0].Zip);
            setNotifyEmail(data[0].ReceiveEmail);
            setNotifySms(data[0].ReceiveTexts);
          }

        })
    }
  }, [user])


  if (isLoading || !user) {
    return <div>Loading ...</div>;
  }

  return (
    (isAuthenticated && !isLoading) ? (
      <div className="relative max-w-3xl mx-auto mb-10">
        <form className="space-y-8 divide-y divide-gray-200">
          <div className="pt-8">
            <div>
              <h3 className="text-lg font-medium leading-6 text-gray-900">Personal Information</h3>
              <p className="mt-1 text-sm text-gray-500">Please provide your personal contact information.</p>
            </div>
            <div className="mt-6 grid md:grid-cols-6 gap-6 sm:grid-cols-1">
            
              <div className="md:grid-cols-1 sm:col-span-3">
                <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                  First name
                </label>
                <div className="mt-1">
                  {/* <input
                    type="text"
                    name="first-name"
                    id="first-name"
                    autoComplete="given-name"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gold-500 focus:ring-gold-500 sm:text-sm"
                    onChange={e => setFirstName(e.currentTarget.value)}
                    value={firstName}
                  /> */}
                  {firstName}
                </div>
              </div>

              <div className="sm:col-span-3">
                <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                  Last name
                </label>
                <div className="mt-1">
                  {/* <input
                    type="text"
                    name="last-name"
                    id="last-name"
                    autoComplete="family-name"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gold-500 focus:ring-gold-500 sm:text-sm"
                    onChange={e => setLastName(e.currentTarget.value)}
                    value={lastName}
                  /> */}
                  {lastName}
                </div>
              </div>
              
              {
                email !== user?.email &&
                <div className="sm:col-span-3">
                <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                  Login
                </label>
                <div className="mt-1">
                  {/* <input
                    type="text"
                    name="street-address"
                    id="street-address"
                    autoComplete="street-address"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gold-500 focus:ring-gold-500 sm:text-sm"
                    onChange={e => setAddress1(e.currentTarget.value)}
                    value={address1}
                  /> */}
                  {user.email}
                </div>
              </div>
              }

              <div className="sm:col-span-3">
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Email address {email === user?.email && '(login)'}
                </label>
                <div className="mt-1">
                  {/* <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gold-500 focus:ring-gold-500 sm:text-sm"
                    onChange={e => setEmail(e.currentTarget.value)}
                    value={email}
                  /> */}
                  {email}
                </div>
              </div>

              <div className="sm:col-span-3">
                <label htmlFor="phone-number" className="block text-sm font-medium text-gray-700">
                  Phone
                </label>
                <div className="mt-1">
                  {/* <input
                    type="tel"
                    name="phone-number"
                    id="phone-number"
                    autoComplete="phone-number"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gold-500 focus:ring-gold-500 sm:text-sm"
                    onChange={e => setPhone(e.currentTarget.value)}
                    value={phone}
                  /> */}
                  {phone}
                </div>
              </div>

              <div className="sm:col-span-3">
                <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                  Street address
                </label>
                <div className="mt-1">
                  {/* <input
                    type="text"
                    name="street-address"
                    id="street-address"
                    autoComplete="street-address"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gold-500 focus:ring-gold-500 sm:text-sm"
                    onChange={e => setAddress1(e.currentTarget.value)}
                    value={address1}
                  /> */}
                  {address1}
                </div>
              </div>


              <div className="sm:col-span-2">
                <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                  City
                </label>
                <div className="mt-1">
                  {/* <input
                    type="text"
                    name="city"
                    id="city"
                    autoComplete="address-level2"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gold-500 focus:ring-gold-500 sm:text-sm"
                    onChange={e => setCity(e.currentTarget.value)}
                    value={city}
                  /> */}
                  {city}
                </div>
              </div>

              <div className="sm:col-span-2">
                <label htmlFor="region" className="block text-sm font-medium text-gray-700">
                  State
                </label>
                <div className="mt-1">
                  {/* <input
                    type="text"
                    name="region"
                    id="region"
                    autoComplete="address-level1"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gold-500 focus:ring-gold-500 sm:text-sm"
                    onChange={e => setState(e.currentTarget.value)}
                    value={state}
                  /> */}
                  {state}
                </div>
              </div>

              <div className="sm:col-span-2">
                <label htmlFor="postal-code" className="block text-sm font-medium text-gray-700">
                  ZIP
                </label>
                <div className="mt-1">
                  {/* <input
                    type="text"
                    name="postal-code"
                    id="postal-code"
                    autoComplete="postal-code"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gold-500 focus:ring-gold-500 sm:text-sm"
                    onChange={e => setZip(e.currentTarget.value)}
                    value={zip}
                  /> */}
                  {zip}
                </div>
              </div>

            </div>

            <div className="pt-8">
              <div>
                <h3 className="text-lg font-medium leading-6 text-gray-900">Notifications</h3>
                <p className="mt-1 text-sm text-gray-500">
                  We will notify you upcomomg practices, tournaments and schedule changes.
                </p>
              </div>
              <div className="mt-6">
                <div className='mt-6 grid md:grid-cols-2 gap-6 sm:grid-cols-6'>
                  <div className="sm:col-span-6">
                    <div className="flex gap-6">
                      <div>
                        <Switch
                          checked={notifyEmail}
                          onChange={setNotifyEmail}
                          className={classNames(
                            notifyEmail ? 'bg-gold-600' : 'bg-gray-200',
                            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-gold-500 focus:ring-offset-2'
                          )}
                        >
                          <span className="sr-only">Notify via email</span>
                          <span
                            aria-hidden="true"
                            className={classNames(
                              notifyEmail ? 'translate-x-5' : 'translate-x-0',
                              'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                            )}
                          />
                        </Switch>
                      </div>
                      <div>
                        Notify me via email
                      </div>
                    </div>
                  </div>
                  <div className="sm:col-span-6">
                    <div className="flex gap-6">
                      <div>
                        <Switch
                          checked={notifySms}
                          onChange={setNotifySms}

                          className={classNames(
                            notifySms ? 'bg-gold-600' : 'bg-gray-200',
                            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-gold-500 focus:ring-offset-2'
                          )}
                        >
                          <span className="sr-only">Notify via sms</span>
                          <span
                            aria-hidden="true"
                            className={classNames(
                              notifySms ? 'translate-x-5' : 'translate-x-0',
                              'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                            )}
                          />
                        </Switch>
                      </div>
                      <div>
                        Notify me via SMS text
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Athletes */}
          <div className="pt-8">
            <div>
              <h3 className="text-lg font-medium leading-6 text-gray-900">Registrations</h3>
              <p className="mt-1 text-sm text-gray-500">
                You can see a list of your registrations below.
              </p>
              <div className="mt-8 flex flex-col">
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                          <tr>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                              Name
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                              Info
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                              Fee
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                              Receipt
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {registrations.map((registration) => (
                            <tr key={registration.Id}>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                {`${registration.AthleteFirstName} ${registration.AthleteLastName}`}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{registration.Grade}, {registration.Gender === 'Girl' ? 'F' : registration.Gender === 'Boy' ? 'M' : 'Unknown'}, { moment(registration.Birthday).format('MM/DD/YYYY') }</td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">${registration.Team.RegistrationFee}</td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">Confirmation #{registration.ConfirmationNumber}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    ) : null
  );
};

export default Profile;