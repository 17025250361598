import './App.css';
import './tailwind.output.css';


function Privacy() {
    return (
        <div className='max-w-3xl mx-auto'>
            <h1 className='font-bold text-2xl mb-3'>Privacy Policy</h1>
        </div>
    );
}

export default Privacy;
