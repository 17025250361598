import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'


function classNames(...classes: Array<string>) {
    return classes.filter(Boolean).join(' ')
}

function FAQ() {

    const faqs = [
        {
            question: `What is this year's tournament schedule?`,
            answer: 'You can view the schedule page to see the tentative tournament dates for this year. The dates are color-coded for boys/girls touraments. The dates will be adjusted once we have confirmed with the tournament host and paid tournament fees.',
        },
        {
            question: `Can my 3rd grader play?`,
            answer: 'Allowing a 3rd grader to "play-up" with the 4th grade team will be based on the number of registrations that area received. Feel free to reach out to us at info@sibleyeastbasketball.com if you are interested in registering your 3rd grader.',
        },
        {
            question: `Do you offer scholarships or financial assistance?`,
            answer: 'We do have some resources available if your are in need of help. Please contact us at info@sibleyeastbasketball.com for more information.',
        },
        {
            question: `Is my athlete guaranteed play time?`,
            answer: 'Equal playing time is not guaranteed for every player. This a competitive league and our goal is to work with ever athlete to help them reach their full potential and maximize their playing time.',
        },
    ]

    return (
        <div className="bg-gray-50">
            <div className="mx-auto max-w-7xl py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
                <div className="mx-auto max-w-3xl divide-y-2 divide-gray-200">
                    <h2 className="text-center text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                        Frequently asked questions
                    </h2>
                    <dl className="mt-6 space-y-6 divide-y divide-gray-200">
                        {faqs.map((faq) => (
                            <Disclosure as="div" key={faq.question} className="pt-6">
                                {({ open }) => (
                                    <>
                                        <dt className="text-lg pt-1">
                                            <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-400">
                                                <span className="font-medium text-gray-900">{faq.question}</span>
                                                <span className="ml-6 flex h-7 items-center">
                                                    <ChevronDownIcon
                                                        className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
                                                        aria-hidden="true"
                                                    />
                                                </span>
                                            </Disclosure.Button>
                                        </dt>
                                        <Disclosure.Panel as="dd" className="mt-2 pr-12">
                                            <p className="text-base text-gray-500">{faq.answer}</p>
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                        ))}
                    </dl>
                </div>
            </div>
        </div>
    );
}

export default FAQ;